import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className='App-address'>
          Lalelelor 1, Giroc, Romania
        </p>
        <p>
          tel: +40731391245
        </p>
        <p>
          email: office@adarvi.ro
        </p>
        <a
          className="App-link"
          href="mailto:office@adarvi.ro"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact us
        </a>
      </header>
    </div>
  );
}

export default App;
